<template>
  <div class="col-12 text-center grey_bg p-3 py-md-5 px-md-4 mt-4">
    <div class="container-lg" >
      <div class="row justify-content-center">
        <div class="col-12 p-0" v-if="isUK">
          <div class="row">
            <div class="col-6 col-lg-4 mb-3 px-2">
              <card icon="icon" @goTo="goTo('donations')" :hasEntries="countTransactions" :isLoading="countTransactionsStatus !== 'ready'">
                <IconHeart color="gold" size="size28" />
                <template v-slot:heading>
                  My Donations <span v-if="countTransactions">({{countTransactions}})</span>
                </template>
              </card>
            </div>
            <div class="col-6 col-lg-4 mb-3 px-2">
              <RecurringSummaryCountCard />
              <!-- <card icon="icon" @goTo="goTo('/recurringdonations')" :hasEntries="countRecurringDonations" :isLoading="countRecurringDonationsStatus !== 'ready'">
                <IconRecurring color="gold" size="size28" />
                <template v-slot:heading>
                  My Recurring Donations <span v-if="countRecurringDonations">({{countRecurringDonations}})</span>
                </template>
              </card> -->
            </div>
            <div class="col-6 col-lg-4 mb-3 px-2">
              <SummaryCountCard />
            </div>
            <div class="col-6 mb-3 px-2">
              <card icon="icon" @goTo="goTo('/organisations')" :hasEntries="countOrganisations" :isLoading="countOrganisationsStatus !== 'ready'">
                <IconOrganisations color="gold" size="size28" />
                <template v-slot:heading>
                  My Organisations <span v-if="countOrganisations">({{countOrganisations}})</span>
                </template>
              </card>
            </div>
            <div class="col-6 mb-3 px-2">
              <card icon="icon" @goTo="goTo('/giftaidpreferences')" isManage="true">
                <IconGiftAid color="gold" size="size28" />
                <template v-slot:heading>
                  My GiftAid Preferences
                </template>
              </card>
            </div>
          </div>
        </div>
        <div class="col-12 p-0" v-else>
          <div class="row">
            <div class="col-6 col-lg-3 mb-3 px-2">
              <card icon="icon" @goTo="goTo('donations')" :hasEntries="countTransactions" :isLoading="countTransactionsStatus !== 'ready'">
                <IconHeart color="gold" size="size28" />
                <template v-slot:heading>
                  My Donations <span v-if="countTransactions">({{countTransactions}})</span>
                </template>
              </card>
            </div>
            <div class="col-6 col-lg-3 mb-3 px-2">
              <RecurringSummaryCountCard />
            </div>
            <div class="col-6 col-lg-3 mb-3 px-2">
              <SummaryCountCard />
            </div>
            <div class="col-6 col-lg-3 mb-3 px-2">
              <card icon="icon" @goTo="goTo('/pledges')" :isDisabled="!countPledge || countPledge === 0" :hasEntries="countPledge" :isLoading="countPledgeStatus !== 'ready'">
                <IconBank color="gold" size="size28" />
                <template v-slot:heading>
                  My Pledges <span v-if="countPledge">({{countPledge}})</span>
                </template>
              </card>
            </div>
            <div class="col-6 col-lg-3 mb-3 px-2">
              <card icon="icon" @goTo="goTo('/donorgroups')" :hasEntries="countGroup" :isLoading="countGroupStatus !== 'ready'">
                <IconGroups color="gold" size="size28" />
                <template v-slot:heading>
                  My Donor groups <span v-if="countGroup">({{countGroup}})</span>
                </template>
              </card>
            </div>
            <div class="col-6 col-lg-3 mb-3 px-2">
              <card icon="icon" @goTo="goTo('/organisations')" :hasEntries="countOrganisations" :isLoading="countOrganisationsStatus !== 'ready'">
                <IconOrganisations color="gold" size="size28" />
                <template v-slot:heading>
                  My Organisations <span v-if="countOrganisations">({{countOrganisations}})</span>
                </template>
              </card>
            </div>
            <div class="col-6 col-lg-3 mb-3 px-2">
              <card icon="icon" @goTo="goTo('/managezakaah')" :hasEntries="countZakaat" :isLoading="countZakaatStatus !== 'ready'">
                <IconCalculator color="gold" size="size28" />
                <template v-slot:heading>
                  Zakaah Calculations <span v-if="countZakaat">({{countZakaat}})</span>
                </template>
              </card>
            </div>
            <div class="col-6 col-lg-3 mb-3 px-2">
              <card icon="icon" @goTo="goTo('/manage18a')" :hasEntries="countTaxCertificate" :isLoading="countTaxCertificateStatus !== 'ready'" :isView="true">
                <Icon18a color="gold" size="size28" />
                <template v-slot:heading>
                  Section 18A <span v-if="countTaxCertificate">({{countTaxCertificate}})</span>
                </template>
              </card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Card: defineAsyncComponent(() => import('@/views/ProfileNavCard.vue')),
    RecurringSummaryCountCard: defineAsyncComponent(() => import('../pages/manage/recurringdonations/components/SummaryCountCard.vue')),
    SummaryCountCard: defineAsyncComponent(() => import('../pages/manage/sadaqah/components/SummaryCountCard.vue')),
    IconHeart: defineAsyncComponent(() => import('@/components/icons/IconHeart.vue')),
    // IconRecurring: defineAsyncComponent(() => import('@/components/icons/IconRecurring.vue')),
    IconBank: defineAsyncComponent(() => import('@/components/icons/IconBank.vue')),
    IconGroups: defineAsyncComponent(() => import('@/components/icons/IconGroups.vue')),
    IconOrganisations: defineAsyncComponent(() => import('@/components/icons/IconOrganisations.vue')),
    IconCalculator: defineAsyncComponent(() => import('@/components/icons/IconCalculator.vue')),
    Icon18a: defineAsyncComponent(() => import('@/components/icons/Icon18a.vue')),
    IconGiftAid: defineAsyncComponent(() => import('@/components/icons/IconGiftAid.vue'))
  },
  name: 'ProfileCardsSection',
  async mounted () {
    this.getAllCounts()
  },
  methods: {
    ...mapActions(['getAllCounts']),
    goTo (val) {
      if (val === 'donations') {
        this.$emit('goToDonations')
      } else {
        this.$router.push(val)
      }
    }
  },
  computed: {
    ...mapGetters([
      'countRecurringDonations',
      'countDailySadaqa',
      'countTransactions',
      'countTaxCertificate',
      'countPledge',
      'countGroup',
      'countOrganisations',
      'countZakaat',
      'countRecurringDonationsStatus',
      'countDailySadaqaStatus',
      'countTransactionsStatus',
      'countTaxCertificateStatus',
      'countPledgeStatus',
      'countGroupStatus',
      'countOrganisationsStatus',
      'countZakaatStatus'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    }
  }
}
</script>
